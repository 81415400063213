<template>
  <div>
    <h3>Manage Account Security</h3>
    <v-app>
      <div class="row">
        <div class="col-xl-12">
          <v-row>
            <v-col cols="12" md="5" class="card p-10 mt-10 mr-10">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h3>Setup MFA - Authenticator App</h3>
                    <p>Use multi-factor authentication (MFA) to add an extra layer of security to your account.</p>
                  </div>
                  <div class="col-md-4 text-right">
                    <p>
                      <img
                              src="https://cdn2.iconfinder.com/data/icons/plex-for-android/96/authenticator.png"
                              style="max-width: 64px"
                      />
                    </p>
                  </div>
                  <v-btn
                          v-if="!this.user.is_google_two_factor_authentication_enabled"
                          class="btn btn-primary"
                          text
                          :loading="isGoogleAuthLoading"
                          @click="getGoogleAuthenticatorCode"
                  >Setup Authenticator App</v-btn>
                  <v-btn
                          v-else
                          color="white"
                          class="btn btn-primary bg-danger"
                          :loading="isGoogleAuthLoading"
                          @click="deActivateGoogleAuthenticator"
                  >Deactivate Authenticator App</v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="5" class="card p-10 mt-10 mr-10">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h3>Setup MFA - Email Verification</h3>
                    <p>Use multi-factor authentication (MFA) to add an extra layer of security to your account.</p>
                  </div>
                  <div class="col-md-4 text-right">
                    <p>
                      <img
                              src="https://img.icons8.com/plasticine/100/000000/important-mail.png"
                              style="max-width: 64px"
                      />
                    </p>
                  </div>
                  <v-btn
                          class="btn btn-primary"
                          v-if="!this.user.is_email_authentication_enabled"
                          @click="getEmailAuthenticator"
                  >Setup Email Authenticator</v-btn>
                  <v-btn
                          v-else
                          class="btn btn-primary"
                          @click="deActivateEmailAuthenticator"
                  >Deactive Email Authenticator</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5" class="card p-10 mt-10 mr-10">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h3>Setup Mobile Recovery</h3>
                    <p>Setup your mobile and recover your account in case of emergency and add an extra layer of security to your account.</p>
                  </div>
                  <div class="col-md-4 text-right">
                    <p>
                      <img
                              src="https://shuftipro.com/wp-content/uploads/Quick_Quick.svg"
                              style="max-width: 64px"
                      />
                    </p>
                  </div>
                  <div class="col-md-12" v-if="user.is_phone_verified">
                    <div class="row">
                      <div class="col-md-8">
                        <h4>
                          {{user.phone}}
                          <i class="flaticon2-check-mark kt-font-success kt-font-big"></i>
                        </h4>
                      </div>
                      <div class="col-md-4">
                        <v-btn
                                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                class="btn-save-popup"
                                text
                                @click="showMobileInput = true"
                        >Change Number</v-btn>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="showMobileInput">
                    <div class="row">
                      <div class="col-md-12 text-right">
                        <p>
                          <v-text-field
                              label="Mobile No"
                              :append-icon="user.is_phone_verified ? 'done_all' : 'done_outline' "
                              v-model="mobileNo"
                              densed
                              outlined
                              clearable
                          ></v-text-field>
                        </p>
                      </div>
                      <v-btn
                              class="btn btn-primary mt-1"
                              :disabled="!mobileNo"
                              @click="sendVerificationCode"
                      >Verify</v-btn>
                    </div>
                  </div>

                  <!-- <v-btn
                    color="white--text v-btn theme--light elevation-2 v-size--large primary"
                    class="btn-save-popup"
                    text
                    @click="showgoogleAuthenticatorSetup"
                  >Remove</v-btn>-->
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="5" class="card p-10 mt-10 mr-10">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h3>Password</h3>
                    <p>Setup secure password to access and manage your account.</p>
                  </div>
                  <div class="col-md-4 text-right">
                    <p>
                      <img
                              src="https://img.icons8.com/plasticine/100/000000/password.png"
                              style="max-width: 64px"
                      />
                    </p>
                  </div>
                  <v-btn
                      class="btn btn-primary mt-1"
                          text
                          @click="showChangePasswordDialog"
                  >Change Password</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-dialog scrollable
              v-model="googleAuthenticatorSetup"
              :loading="isGoogleAuthLoading"
              persistent
              max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Setup Authenticator</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12">
                    <ul class="mfa-instructions">
                      <li>Get the Google, Microsoft Authenticator or similar App from the Apple AppStore or Google PlayStore</li>
                      <li>In the app, select account setup</li>
                      <li>Choose to Scan Barcode, then scan code below</li>
                    </ul>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-app id="inspire">
                      <v-row align="center" justify="center">
                        <progressive-img
                                :src="authenticator.google.image_url"
                                :lazy-src="`https://picsum.photos/200/200?random`"
                                max-width="200"
                                max-height="200"
                                aspect-ratio="1"
                                class="grey lighten-2"
                        >
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </progressive-img>
                      </v-row>
                    </v-app>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <a href="#" color="red" @click="googleSecret= !googleSecret">or add your key manually</a>
                    <v-row class="mfa-secret-key" v-if="googleSecret">
                      <v-col cols="12">
                        <p>
                          <b>Account:</b>
                          {{authenticator.google.account}}
                        </p>
                        <p>
                          <b>Secret Key:</b>
                          {{authenticator.google.secret_key}}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                            label="Enter Verification Code"
                            v-model="authenticator.google.verification_code"
                            required
                            densed
                            outlined
                    ></v-text-field>
                    <p
                            class="text-danger font-weight-bold"
                            v-if="$v.authenticator.google.verification_code.$error"
                    >Please enter a verification code</p>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closegoogleAuthenticatorSetup">Close</v-btn>
            <v-btn
                    class="btn btn-primary"
                    text
                    @click.prevent="activateGoogleAuthenticator"
            >Save & Activate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog scrollable v-model="mobileRecoverySetup" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Mobile Verification Setup</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12">
                    <ul class="mfa-instructions">
                      <li>Verification code has been sent to your Mobile No : {{mobileNo}}</li>
                      <!-- <li>Get the Google Authenticator App from the Apple AppStore or Google PlayStore</li> -->
                    </ul>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                            label="Enter Verification Code"
                            v-model="mobileVerificationCode"
                            required
                            dense
                            outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" :loading="loading" text @click="closeMobileDialog">Close</v-btn>
            <v-btn
                    color="white--text v-btn theme--light elevation-2 v-size--large primary"
                    class="btn-save-popup"
                    text
                    @click.once="verfyMobileCode"
            >Save & Activate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog scrollable v-model="emailAuthenticationSetup" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Setup Email Authenticator</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12">
                    <ul class="mfa-instructions">
                      <li>Setup your email to add an extra layer of security to your account.</li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="blue darken-1"
                    :loading="loading"
                    text
                    @click="closeEmailAuthenticatorDialog"
            >Close</v-btn>
            <v-btn
                class="btn btn-primary"
                    text
                    :loading="isEmailAuthLoading"
                    @click.once="activateEmailAuthenticator"
            >Activate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog scrollable v-model="changePassowrdDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Change Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12">
                    <ul class="mfa-instructions">
                      <li>You will receive an email to reset your passowrd.</li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="blue darken-1"
                    :loading="loading"
                    text
                    @click="closeChangePasswordDialog"
            >Close</v-btn>
            <v-btn
                    color="white--text v-btn theme--light elevation-2 v-size--large primary"
                    class="btn-save-popup"
                    text
                    :loading="isChangePasswordLoading"
                    @click.once="sendPasswordResetEmail"
                    :disabled="emailVerificationMailRequested"
            >{{emailVerificationMailRequested ? 'Please wait for a minute' :'Request Email'}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import KTPortlet from "@/views/partials/content/Portlet.vue";
  import { mapState } from "vuex";
  import ApiService from "@/common/api.service";
  import { validationMixin } from "vuelidate";
  import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
  import { isMobileNumber } from "@/common/validation";
  import userService from "@/services/user/UserService";
  const UserService = new userService();
  export default {
    mixins: [validationMixin],
    name: "account-security",
    validations: {
      authenticator: {
        google: {
          verification_code: { required }
        }
      }
    },
    data() {
      return {
        user: "",
        showMobileInput: true,
        isGoogleAuthLoading: false,
        isEmailAuthLoading: false,
        isChangePasswordLoading: false,
        emailVerificationMailRequested: false,
        isBusy: false,
        authenticator: {
          google: {
            verification_code: "",
            image_url: "",
            account: "",
            secret_key: ""
          }
        },
        mobileVerificationCode: null,
        user: {
          phone: ""
        },
        mobileRecoverySetup: false,
        mobileNo: null,
        googleAuthenticatorSetup: false,
        emailAuthenticationSetup: false,
        changePassowrdDialog: false,
        googleSecret: false,
        seoDialog: false,
        loading: false,
        page: false
      };
    },
    methods: {
      getGoogleAuthenticatorCode() {
        this.isGoogleAuthLoading = true;
        UserService.requestGoogleAuthenticator().then(res => {
          this.authenticator.google.account = res.data.account;
          this.authenticator.google.secret_key = res.data.secret_key;
          this.authenticator.google.image_url = res.data.image_url;
          this.googleAuthenticatorSetup = true;
          this.isGoogleAuthLoading = false;
        });
      },
      getEmailAuthenticator() {
        this.emailAuthenticationSetup = true;
      },
      activateEmailAuthenticator() {
        this.isEmailAuthLoading = true;
        this.emailAuthenticationSetup = false;
        this.user.is_email_authentication_enabled = true;
        UserService.update(this.user).then(res => {
          this.$snotify.success("Email authenticator activated");
          this.isEmailAuthLoading = false;
          this.emailAuthenticationSetup = false;
          this.user.is_email_authentication_enabled = true;
        });
      },
      deActivateEmailAuthenticator() {
        this.isEmailAuthLoading = true;
        this.emailAuthenticationSetup = false;
        this.user.is_email_authentication_enabled = false;
        UserService.update(this.user).then(res => {
          this.$snotify.success("Email authenticator Deactivated");
          this.isEmailAuthLoading = false;
          this.emailAuthenticationSetup = false;
          this.user.is_email_authentication_enabled = false;
        });
      },

      activateGoogleAuthenticator() {
        this.loading = true;
        this.$v.$touch();
        if (this.$v.authenticator.google.$error) {
        } else {
          UserService.activateGoogleAuthenticator(this.authenticator.google).then(
                  res => {
                    this.authenticator.google = {
                      verification_code: "",
                      image_url: "",
                      account: "",
                      secret_key: ""
                    };
                    this.googleAuthenticatorSetup = false;
                    this.isGoogleAuthLoading = false;
                    this.loading = false;
                    if (res.data.status == "OK") {
                      this.user.is_google_two_factor_authentication_enabled = true;
                      this.$snotify.success("Google authenticator activated");
                    } else this.$snotify.error("Problem occured");
                  }
          );
        }
      },

      deActivateGoogleAuthenticator() {
        this.isGoogleAuthLoading = true;
        UserService.deActivateGoogleAuthenticator().then(res => {
          this.isGoogleAuthLoading = false;
          if (res.data.status == "OK") {
            this.user.is_google_two_factor_authentication_enabled = false;
            this.$snotify.success("Google authenticator Deactivated");
          }
        });
      },

      showGoogleSecret() {
        this.googleSecret = true;
      },
      showgoogleAuthenticatorSetup() {
        this.googleAuthenticatorSetup = true;
      },
      showChangePasswordDialog() {
        this.changePassowrdDialog = true;
      },
      sendMobileCode() {
        UserService.sendMobileVerificationCode().then(res => {
          this.$snotify.success("Verification Code Send to Your Mobile");
        });
      },
      sendVerificationCode() {
        if (this.mobileNo === this.user.phone) {
          this.sendMobileCode();
          this.mobileRecoverySetup = true;
        } else {
          this.user.phone = this.mobileNo;
          ApiService.post("user/update", this.user).then(response => {
            this.$snotify.success("Profile updated");
            this.sendMobileCode();
            this.mobileRecoverySetup = true;
          });
        }
      },
      verfyMobileCode() {
        let data = {
          verification_code: this.mobileVerificationCode
        };
        UserService.verifyMobile(data).then(res => {
          if (res) {
            this.$snotify.success("Mobie Sucessfully Verified");
            this.closeMobileDialog();
          }
        });
      },

      sendPasswordResetEmail() {
        ApiService.post("user/reset/password", {
          email: this.user.email
        })
                .then(response => {
                  this.closeChangePasswordDialog();
                  this.emailVerificationMailRequested = true;
                  this.$snotify.success("Password reset email sent");
                })
                .catch(({ response }) => {
                  this.emailVerificationMailRequested = false;
                  this.$snotify.error("Problem occured");
                });

        setTimeout(() => {
          this.emailVerificationMailRequested = false;
        }, 60000);
      },

      closeMobileDialog() {
        this.mobileRecoverySetup = false;
      },
      closeEmailAuthenticatorDialog() {
        this.emailAuthenticationSetup = false;
      },
      closegoogleAuthenticatorSetup() {
        this.googleAuthenticatorSetup = false;
        this.authenticator.google.account = "";
        this.authenticator.google.secret_key = "";
        this.authenticator.google.image_url = "";
      },
      closeChangePasswordDialog() {
        this.changePassowrdDialog = false;
      }
    },
    mounted() {
      this.user = this.$store.getters.currentUser;
      this.mobileNo = this.user.phone;
      this.user.is_phone_verified == true
              ? (this.showMobileInput = false)
              : (this.showMobileInput = true);
    },
    computed: {},
    watch: {}
  };
</script>
